import { CloseCircleFilled } from '@ant-design/icons';
import { CheckCircle, XCircle, AlertTriangle } from 'react-feather';

import { appColors } from 'constants/colors.constants';

import {
	ChowNowCheckFilled,
	ChowNowCautionFilled,
} from 'components/ChowNowIcon';

import Alcohol from 'assets/icons/classifications/alcohol.svg';
import DairyFree from 'assets/icons/classifications/dairyfree.svg';
import GlutenFree from 'assets/icons/classifications/glutenfree.svg';
import Vegan from 'assets/icons/classifications/vegan.svg';
import Vegetarian from 'assets/icons/classifications/vegetarian.svg';

export const MENU_INFO = { value: 'menu-info', label: 'Menu Info' };
export const MENU_ITEMS = { value: 'menu-items', label: 'Menu Items' };

/** Action Types */
export const SET_CUBOH_MENU_ITEMS = 'SET_CUBOH_MENU_ITEMS';
export const SET_SINGLE_MENU = 'SET_SINGLE_MENU';
export const SET_POS_MENUS = 'SET_POS_MENUS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const UPDATE_CUBOH_MENU_ITEM = 'UPDATE_CUBOH_MENU_ITEM';
export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';
export const CREATE_NEW_CUBOH_ITEM = 'CREATE_NEW_CUBOH_ITEM';
export const UPDATE_MENU_INTEGRATION = 'UPDATE_MENU_INTEGRATION';
export const SET_ALL_PARENT_CHILD_OPTIONS = 'SET_ALL_PARENT_CHILD_OPTIONS';
export const SET_PARENT_CHILD_IS_LOADING = 'SET_PARENT_CHILD_IS_LOADING';
export const SET_PARENT_CHILD_ERROR = 'SET_PARENT_CHILD_ERROR';
export const SET_MERCHANT_INTEGRATIONS = 'SET_MERCHANT_INTEGRATIONS';
export const SET_INTEGRATIONS_IS_LOADING = 'SET_INTEGRATIONS_IS_LOADING';
export const SET_INTEGRATIONS_ERROR = 'SET_MERCHANT_INTEGRATIONS_ERROR';
export const SET_ALL_SUPPORTED_INTEGRATIONS = 'SET_ALL_SUPPORTED_INTEGRATIONS';
export const SET_SUPPORTED_IS_LOADING = 'SET_SUPPORTED_IS_LOADING';
export const SET_SUPPORTED_ERROR = 'SET_SUPPORTED_ERROR';

/** menu management reducer types */
export const CUBOH_MENUS = 'cubohMenus';
export const SINGLE_MENU = 'singleMenu';
export const POS_MENUS = 'posMenus';
export const RESOURCE = 'resource';

export const DISABLED_DUE_TO_LINKED_MERCHANTS_TOOLTIP_TITLE =
	'Please save changes to linked merchants to continue editing your menu.';

export const SPECIAL_HOURS_TOOLTIP =
	'Specify Special/Holiday hours which apply to particular days of the year. These will override the regular hours for the specified days and will automatically be included in any menu publishes within 6 days of the specified hours.';

export const MENU_OBJECT_TYPES = {
	single: 'single_menu',
	menus: 'cuboh_menu',
	categories: 'cuboh_category',
	items: 'cuboh_main_item',
	modifierGroups: 'cuboh_modifier_group',
	modifiers: 'cuboh_modifier',
};

export const SCHEDULE_DAY_OPTIONS = [
	{ value: 'monday', label: 'Mon', ordinal: 1 },
	{ value: 'tuesday', label: 'Tue', ordinal: 2 },
	{ value: 'wednesday', label: 'Wed', ordinal: 3 },
	{ value: 'thursday', label: 'Thu', ordinal: 4 },
	{ value: 'friday', label: 'Fri', ordinal: 5 },
	{ value: 'saturday', label: 'Sat', ordinal: 6 },
	{ value: 'sunday', label: 'Sun', ordinal: 7 },
];

export const TYPED_TIME_FORMATS = [
	'hh:mm a',
	'HH:mm',
	'HHmm',
	'Hmm',
	'ha',
	'hhmma',
	'hmma',
	'hh mm a',
	'hhmm a',
	'h mm a',
	'h a',
	'HH',
	'H',
	'hh mma',
	'hh:mma',
	'h:mma',
	'h:mm a',
];

export const DAYS_AHEAD_TO_PUBLISH_SCHEDULE = 6;
export const START_INDEX = 0;
export const END_INDEX = 1;
export const DEFAULT_SCHEDULE = {
	isNew: true,
	dayIndex: 'sunday',
	startTime: '09:00',
	endTime: '17:00',
};
export const MIDNIGHT = '00:00';
export const CLOSED = 'Closed';
export const OPEN_24_HOURS = 'Open 24 Hours';
export const ALWAYS_OPEN_TIME = '23:59';

export const EMPTY_FIELD = '-';

export const nonRequiredFields = [
	'description',
	'imageUrl',
	'modifierGroup',
	'externalId',
	'specialInstructionsEnabled',
	'internalName',
];
export const MENU_MANAGEMENT_FIELDS = {
	DESCRIPTION: 'description',
	IMAGE_URL: 'imageUrl',
	MODIFIER_GROUP: 'modifierGroup',
	EXTERNAL_ID: 'externalId',
	SPECIAL_INSTRUCTIONS_ENABLED: 'specialInstructionsEnabled',
	INTERNAL_NAME: 'internalName',
};
export const MODIFIER_MINIMUM = 0;
export const MODIFIER_MAXIMUM = 99;

export const CUBOH_MENU_CHILD_MAP = {
	[MENU_OBJECT_TYPES.menus]: MENU_OBJECT_TYPES.categories,
	[MENU_OBJECT_TYPES.categories]: MENU_OBJECT_TYPES.items,
	[MENU_OBJECT_TYPES.items]: MENU_OBJECT_TYPES.modifierGroups,
	[MENU_OBJECT_TYPES.modifierGroups]: MENU_OBJECT_TYPES.modifiers,
	[MENU_OBJECT_TYPES.modifiers]: MENU_OBJECT_TYPES.modifierGroups,
};

export const CUBOH_MENU_PARENT_MAP = {
	[MENU_OBJECT_TYPES.categories]: [MENU_OBJECT_TYPES.menus],
	[MENU_OBJECT_TYPES.items]: [MENU_OBJECT_TYPES.categories],
	[MENU_OBJECT_TYPES.modifierGroups]: [
		MENU_OBJECT_TYPES.items,
		MENU_OBJECT_TYPES.modifiers,
	],
	[MENU_OBJECT_TYPES.modifiers]: [MENU_OBJECT_TYPES.modifierGroups],
};

export const ITEM_TYPES_FOR_PARENT_CHILD = [
	MENU_OBJECT_TYPES.categories,
	MENU_OBJECT_TYPES.items,
	MENU_OBJECT_TYPES.modifierGroups,
	MENU_OBJECT_TYPES.modifiers,
];

export const INTEGRATION_STATUS = {
	published: {
		label: 'Published',
		color: appColors.publishMenuPublished,
	},
	modified: {
		label: 'Modified',
		color: appColors.publishMenuModified,
	},
	in_progress: {
		label: 'In Progress',
		color: appColors.publishMenuInProgress,
	},
	failed: {
		label: 'Failed',
		color: appColors.publishMenuFailed,
	},
	pulled: {
		label: 'Imported',
		color: appColors.publishMenuImported,
	},
	inactive: {
		label: 'Inactive',
		color: appColors.publishMenuInactive,
	},
};

export const defaultImageFileName = 'default-menu-item-image.png';

export const errorImageFileName = 'invalid-menu-item-image.png';

export const ALCOHOLIC = 'alcoholic';
export const ALCOHOLIC_NOT_AVAILABLE_ALONE = 'alcoholic_not_available_alone';
export const ALCOHOLIC_AVAILABLE_ALONE = 'alcoholic_available_alone';

export const CLASSIFICATIONS = {
	[ALCOHOLIC_NOT_AVAILABLE_ALONE]: {
		label: 'Alcoholic - Not Available Alone',
		alone: false,
		classification: 'alcoholic',
		icon: Alcohol,
	},
	[ALCOHOLIC_AVAILABLE_ALONE]: {
		label: 'Alcoholic - Available Alone',
		alone: true,
		classification: 'alcoholic',
		icon: Alcohol,
	},
	dairy_free: {
		label: 'Dairy Free',
		alone: true,
		classification: 'dairy_free',
		icon: DairyFree,
	},
	gluten_free: {
		label: 'Gluten Free',
		alone: true,
		classification: 'gluten_free',
		icon: GlutenFree,
	},
	vegetarian: {
		label: 'Vegetarian',
		alone: true,
		classification: 'vegetarian',
		icon: Vegetarian,
	},
	vegan: {
		label: 'Vegan',
		alone: true,
		classification: 'vegan',
		icon: Vegan,
	},
};

export const SKIP_CLASSIFICATIONS = {
	[ALCOHOLIC_NOT_AVAILABLE_ALONE]: {
		label: 'Alcoholic - Not Available Alone',
		alone: false,
		classification: 'alcoholic',
		icon: Alcohol,
	},
	[ALCOHOLIC_AVAILABLE_ALONE]: {
		label: 'Alcoholic - Available Alone',
		alone: true,
		classification: 'alcoholic',
		icon: Alcohol,
	},
	dairy_free: {
		label: 'Dairy Free',
		alone: true,
		classification: 'dairy_free',
		icon: DairyFree,
	},
	gluten_free: {
		label: 'Gluten Free',
		alone: true,
		classification: 'gluten_free',
		icon: GlutenFree,
	},
	vegetarian: {
		label: 'Vegetarian',
		alone: true,
		classification: 'vegetarian',
		icon: Vegetarian,
	},
	vegan: {
		label: 'Vegan',
		alone: true,
		classification: 'vegan',
		icon: Vegan,
	},
	ALCOHOL: {
		label: 'Skip Tax: Alcohol',
		alone: true,
		classification: 'ALCOHOL',
		skipTax: true,
	},
	NO_TAX: {
		label: 'Skip Tax: No Tax',
		alone: true,
		classification: 'NO_TAX',
		skipTax: true,
	},
	SUGARED_DRINK: {
		label: 'Skip Tax: Sugared Drink',
		alone: true,
		classification: 'SUGARED_DRINK',
		skipTax: true,
	},
	BAKED_GOODS: {
		label: 'Skip Tax: Baked Goods',
		alone: true,
		classification: 'BAKED_GOODS',
		skipTax: true,
	},
	NON_FOOD: {
		label: 'Skip Tax: Non Food',
		alone: true,
		classification: 'NON_FOOD',
		skipTax: true,
	},
	GST_ONLY: {
		label: 'Skip Tax: GST Only',
		alone: true,
		classification: 'GST_ONLY',
		skipTax: true,
	},
	PST_ONLY: {
		label: 'Skip Tax: PST Only',
		alone: true,
		classification: 'PST_ONLY',
		skipTax: true,
	},
};

export const SKIP_ONLY_TAXES = [
	'ALCOHOL',
	'NO_TAX',
	'SUGARED_DRINK',
	'BAKED_GOODS',
	'NON_FOOD',
	'GST_ONLY',
	'PST_ONLY',
];

export const MENU_CACHE_READY = 'MENU_CACHE_READY';
export const MENU_CACHE_FAILED = 'MENU_CACHE_FAILED';

export const TYPE_TO_READABLE_STRING_MAP = {
	cuboh_menu: 'Menu',
	cuboh_category: 'Category',
	cuboh_main_item: 'Main Item',
	cuboh_modifier_group: 'Modifier Group',
	cuboh_modifier: 'Modifier',
};

export const CHOWNOW_TYPE_TO_READABLE_STRING_MAP = {
	cuboh_menu: 'Menu',
	cuboh_category: 'Category',
	cuboh_main_item: 'Item',
	cuboh_modifier_group: 'Mod Group',
	cuboh_modifier: 'Modifier',
};

export const VALIDATION_IN_PROGRESS_STATUS = 'validation_in_progress';

export const MENU_DUPLICATION_SUCCESSFUL = 'MENU_DUPLICATION_SUCCESSFUL';
export const MENU_DUPLICATION_FAILED = 'MENU_DUPLICATION_FAILED';
export const MENU_IMPORT_SUCCESSFUL = 'MENU_IMPORT_SUCCESSFUL';
export const MENU_IMPORT_FAILED = 'MENU_IMPORT_FAILED';

export const ALCOHOL_CHECK_SUCCESSFUL = 'ALCOHOL_CHECK_SUCCESSFUL';
export const ALCOHOL_CHECK_FAILED = 'ALCOHOL_CHECK_FAILED';
export const MENU_VALIDATION_SUCCESSFUL = 'MENU_VALIDATION_SUCCESSFUL';
export const MENU_VALIDATION_FAILED = 'MENU_VALIDATION_FAILED';
export const PUBLISH_MENU_VALIDATION_SUCCESSFUL =
	'PUBLISH_MENU_VALIDATION_SUCCESSFUL';
export const PUBLISH_MENU_VALIDATION_FAILED = 'PUBLISH_MENU_VALIDATION_FAILED';
export const INTEGRATION_STATUS_CHANGED = 'INTEGRATION_STATUS_CHANGED';
export const PUBLISH_MENU_VALIDATION_IN_PROGRESS_STATUS =
	'validation_in_progress';
export const LATEST_ROLLOVER_TIME = '06:00';

export const MENU_SOURCE_TYPES = {
	SkipTheDishes: 'app',
	DoorDash: 'app',
	'Uber Eats': 'app',
	Square: 'pos',
	Revel: 'pos',
	Clover: 'pos',
	ChowNow: 'app',
	Lunchbox: 'app',
	Auphan: 'pos',
	Ritual: 'app',
	Sociavore: 'app',
	'Cloned Menu': 'clone',
	Cuboh: 'default',
};

export const MENU_SOURCE_TAG_COLOURS = {
	pos: '#A4C2D8',
	app: '#A1ECBB',
	default: '#D1ADDFF',
	text: '#16173E',
	clone: '#D4F182',
};

export const RESULT_ICON = {
	pass: { Icon: CheckCircle, stroke: appColors.success, type: 'success' },
	warning: { Icon: AlertTriangle, stroke: appColors.warning, type: 'warning' },
	fail: { Icon: XCircle, stroke: appColors.danger, type: 'error' },
};

export const CHOWNOW_RESULT_ICON = {
	pass: { Icon: ChowNowCheckFilled, stroke: '#0e7a4a', type: 'success' },
	warning: { Icon: ChowNowCautionFilled, stroke: '#b2afa4', type: 'neutral' },
	fail: { Icon: CloseCircleFilled, stroke: '#cc1b00', type: 'error' },
};

export const TAX_SELECT_OPTIONS = {
	DEFAULT: 'default',
	CUSTOM: 'custom',
};

export const CLONED_MENU_SOURCE = 'Cloned Menu';

export const CUBOH_MENU_SOURCE = 'Cuboh';

export const CUBOH_INTEGRATION = 'cuboh';

export const SPECIAL_INSTRUCTIONS = {
	key: 'specialInstructionsEnabled',
	apps: {
		ritual: 'Ritual',
		ubereats: 'UberEats',
	},
};

export const SECONDARY_PRICES = {
	key: 'secondaryPrice',
	apps: {
		doordash: 'DoorDash',
	},
};

export const SUPPORTS_DISABLED = ['ChowNow', 'Cuboh'];

export const PUBLISH_NOTICE =
	'The menus have been successfully published to the external integration queue and may, depending on the platform, take up to 90 minutes to go live.';

export const PUBLISH_FAILURE_REASONS = {
	500: 'There was an error while building your menus. Please refresh the page and try again. If the problem persists, please contact support for assistance.',
	400: 'There was an issue with your request. Please refresh the page and try again. If the problem persists, please contact support for assistance.',
};

export const OLDEST_POSSIBLE_PUBLISH_DATE_IN_MILLISECONDS = 1546329600000;

export const FIELD_INPUT_TYPES = {
	NUMBER: 'number',
	PARENT: 'parent',
	PRICE: 'price',
	IMAGE: 'photo',
	UPLOAD: 'upload',
	CLASSIFICATIONS: 'classifications',
	CHECKBOX: 'checkbox',
	MULTIPLE_MODIFIERS_CHECKBOX: 'allowMultipleModifiersCheckbox',
};

export const PUBLISH_LOCKED_TOOLTIP =
	'Publishing is not allowed yet, the Cuboh team is still preparing this account.';

export const PUBLISH_ALL_TOOLTIP =
	"Publish all this merchant's menus to all integrations.";

export const ALL_MENUS_PUBLISHED_TOOLTIP =
	'This menu is up-to-date on all integrations.';

export const NO_INTEGRATIONS_TOOLTIP =
	'This menu has no linked integrations to publish to.';

export const PUBLISH_TOOLTIP = 'Publish Menus';
