import { message } from 'antd';
import axios from 'axios';

import { PERMISSION_ERROR } from 'constants/auth.constants';

import { getAuthorizationHeader } from 'utils/auth.helper';

export async function createCoreAxiosInstance() {
	const instance = axios.create({
		baseURL: process.env.REACT_APP_SERVER_URL,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: getAuthorizationHeader(),
		},
	});

	// If we encounter a permissions error from core - have a flash message appear.
	instance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.response.status === 403) {
				message.warning(PERMISSION_ERROR);
			}

			throw error;
		},
	);

	return instance;
}

export async function createMenuMappingV2AxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_MENU_MAPPING_V2_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: getAuthorizationHeader(),
		},
	});
}

export async function createMenuMappingAxiosInstance(companyID) {
	return axios.create({
		baseURL: `${process.env.REACT_APP_MENU_MAPPER_URL}/V2/company/${companyID}`,
		headers: {
			'Content-Type': 'application/json',
			Authorization: getAuthorizationHeader(),
		},
	});
}

export async function createReportAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_CUBEJS_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: getAuthorizationHeader(),
		},
	});
}

export async function createMenuManagementAxiosInstance(
	contentType = 'application/json',
) {
	return axios.create({
		baseURL: process.env.REACT_APP_MENU_MANAGEMENT_URL,
		headers: {
			Accept: 'application/json',
			'Content-Type': contentType,
			Authorization: getAuthorizationHeader(),
		},
	});
}

export async function createOrchestratorAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_ORCHESTRATOR_URL,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'X-Api-Key': process.env.REACT_APP_ORCHESTRATOR_KEY,
			stage: process.env.REACT_APP_WS_STAGE,
		},
	});
}

export async function createAssetAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_ASSET_URL,
		headers: {},
	});
}

export async function createCountryStateCityAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_COUNTRY_STATE_CITY_URL,
		headers: {
			'X-CSCAPI-KEY': process.env.REACT_APP_COUNTRY_STATE_CITY_API_KEY,
		},
	});
}
