import { createCoreAxiosInstance } from 'createAxiosInstance';
import Cookies from 'universal-cookie';

import { SOURCE } from 'constants/auth.constants';
import { SOURCE_CHOWNOW, SOURCE_CUBOH } from 'constants/chownow.constants';
import {
	ACCESS,
	CUBOH_ACCESS,
	CUBOH_REFRESH,
	REFRESH,
} from 'constants/constants';
export const login = async (values) => {
	const axiosInstance = await createCoreAxiosInstance();
	const { data } = await axiosInstance.post('/api/v2/login', values);
	return data;
};

export const handleForgotPasswordRequest = async (values) => {
	[ACCESS, REFRESH].forEach((key) => localStorage.removeItem(key));
	const axiosInstance = await createCoreAxiosInstance();
	await axiosInstance.post(`/password_reset/`, values);
};

/**
 * Creates a valid path name for a given name,
 * used when creating the storefront route
 *
 * @example "Simba's Place 1" -> "simbas-place-1"
 *
 * @param name: The name to convert to a path
 * @returns URL path version of the name
 */
export const toPathName = (name) =>
	(name ?? '')
		.replace(/&/g, 'and')
		.replace(/[^A-Za-z0-9- ]/g, '')
		.replace(/-/g, ' ')
		.replace(/\s\s+/g, ' ')
		.replace(/\s/g, '-')
		.toLowerCase();

export const fetchUserData = async () => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const {
			data: { response },
		} = await axiosInstance.get('/api/v2/user');
		if (response.companies.length < 1) {
			const errorMessage =
				'User is no longer active - please contact support@cuboh.com for further assistance.';
			throw Error(errorMessage);
		}

		return formatAuthData(response);
	} catch (error) {
		console.error(error);
		throw new Error(error.message);
	}
};

export const fetchFeatureFlags = async () => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const { data } = await axiosInstance.get('/api/v2/feature-flags');
		return data;
	} catch (error) {
		console.error(error);
		throw new Error(error.message);
	}
};

export const formatAuthData = (data) => {
	const companies = data.companies.filter(
		(company) => company.merchants.length > 0 && company.active,
	);

	const payload = {
		user: {
			id: data.userID,
			username: data.username,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			intercomHmac: data.intercomHmac,
			intercomID: data.intercomID,
			permissions: data.permissions,
			tokenClaims: data.tokenClaims,
			isAdmin: data.isAdmin,
			isStaff: data.isStaff,
			companyID: data.companyID,
			userDetails: data.userDetails,
			created: data.created,
			isParentCompanyUser: data.parentCompanyUserID === data.userID,
		},
		merchants: companies.reduce((accumulator, company) => {
			if (company.active) {
				company.merchants.forEach((merchant) => {
					accumulator[merchant.id] = {
						...merchant,
						companyID: company.id,
						storefrontPath: toPathName(merchant.name),
					};
				});
			}
			return accumulator;
		}, {}),
		companies: companies
			.map((company) => {
				delete company.merchants;
				return company;
			})
			.reduce(
				(accumulator, company) => ({
					...accumulator,
					[company.id]: {
						...company,
						storefrontPath: toPathName(company.name),
					},
				}),
				{},
			),
		parentCompany: {
			id: data.parentCompanyID ?? data.company_parentID,
			name: data.parentCompanyName,
			userID: data.parentCompanyUserID,
		},
		selectedTimezone:
			data.companyTimezone ?? data.companies[0].companyTimezone ?? 'US/Eastern',
		proxyUserID:
			data.tokenClaims.proxy_user === undefined
				? null
				: data.tokenClaims.proxy_user,
		selectedCompanyID: companies[0].id,
		selectedMerchantID: null,
	};

	payload.user.flags = {
		SHOW_MENU_MANAGEMENT: getShowMenuManagement(payload.user),
		SHOW_ORDERS_DISPLAY: getShowOrdersDisplay(payload.user),
		SHOW_MENU_MAPPING: getShowMenuMapping(payload.user),
		SHOW_INVOICES: getShowInvoices(payload.user),
		SHOW_LIVE_CHAT: getHideLiveChat(payload.user),
		SHOW_DEFAULT_MODIFIERS: getShowDefaultModifiers(payload.user),
		ALLOW_MULTIPLE_MODIFIERS: getAllowMultipleModifiers(payload.user),
		ALLOW_INDIVIDUAL_PUBLISHES: getAllowIndividualMenuPlatformPush(
			payload.user,
		),
		DISABLE_ORDERS_CONTROL_EMAILS: getShowOrdersControlEmails(payload.user),
		PUBLISH_LOCKED: getPublishLocked(payload.user),
		SHOW_KIOSK: !!payload.user.userDetails?.userMeta?.showKioskPage,
		SHOW_MENU_EXPORT: !!payload.user.userDetails?.userMeta?.showMenuExport,
	};

	return payload;
};

/* Feature Flag Helpers */
export const getShowMenuManagement = (user) => {
	return (
		!!user.userDetails?.userMeta?.enableMenuManagement ||
		!!user.isParentCompanyUser
	);
};

export const getShowOrdersControlEmails = (user) => {
	return !!user.userDetails?.userMeta?.disableOrdersControlEmails;
};

export const getShowOrdersDisplay = (user) => {
	return !!user.userDetails?.userMeta?.enableOrdersDisplay;
};

export const getShowStoreFront = (user) => {
	return user.isParentCompanyUser;
};

export const getShowMenuMapping = (user) => {
	const shouldHideMappingTab = user.userDetails?.userMeta?.hideMappingTab;
	if (shouldHideMappingTab === undefined) {
		return true;
	}

	return !shouldHideMappingTab;
};

export const getShowInvoices = (user) => {
	const hideInvoices = user.userDetails?.userMeta?.hideInvoices;
	if (hideInvoices === undefined) {
		return true;
	}
	return !hideInvoices;
};

export const getHideLiveChat = (user) => {
	const hideLiveChat = user.userDetails?.userMeta?.hideLiveChat;
	if (hideLiveChat === undefined) {
		return true;
	}
	return !hideLiveChat;
};
export const getShowDefaultModifiers = (user) => {
	const hideDefaultModifiers = user.userDetails?.userMeta?.hideDefaultModifiers;
	if (hideDefaultModifiers === undefined) {
		return true;
	}
	return !hideDefaultModifiers;
};
export const getAllowMultipleModifiers = (user) => {
	const allowMultipleModifiers =
		user.userDetails?.userMeta?.allowMultipleModifiers;
	if (allowMultipleModifiers === undefined) {
		return false;
	}
	return allowMultipleModifiers;
};

export const getAllowIndividualMenuPlatformPush = (user) => {
	return user.userDetails?.userMeta?.allowIndividualMenuPlatformPush ?? false;
};

export const getShowRefreshPOS = (user) => {
	return !!user.userDetails?.userMeta?.enableRefreshPOS;
};

export const getPublishLocked = (user) => {
	return !!user.userDetails?.userMeta?.publishLocked ?? false;
};

/**
 * Returns the parseInt of a given value
 *
 * Throws TypeError if value is null, undefined or isNaN
 */

export const formatCompanyID = (id) => {
	const parsedID = parseInt(id);
	if (isNaN(parsedID)) throw TypeError('Provided ID is not a number');
	return parsedID;
};

/**
 * Returns the parseInt of a given value
 * Returns the null if the given value is null, undefined or isNaN
 */

export const formatMerchantID = (id) => {
	const parsedID = parseInt(id);
	if (isNaN(parsedID)) return null;
	return parsedID;
};

const getValueFromLocalStorageOrCookies = (localStorageKey, cookieKey) => {
	const localStorageAccessToken = window.localStorage.getItem(localStorageKey);
	if (localStorageAccessToken) {
		return localStorageAccessToken;
	}

	const cookies = new Cookies(document.cookie, { path: '/' });
	const cookieAccessToken = cookies.get(cookieKey);
	if (cookieAccessToken) {
		return cookieAccessToken;
	}

	return '';
};

export const getSource = () => {
	const cookies = new Cookies(document.cookie, { path: '/' });
	const cookieAccessToken = cookies.get(CUBOH_ACCESS);
	if (cookieAccessToken) return SOURCE_CHOWNOW;
	const source = localStorage.getItem(SOURCE);
	if (source) return source;
	return SOURCE_CUBOH;
};
export const getSourceFromCookie = () => {
	const cookies = new Cookies(document.cookie, { path: '/' });
	const cookieAccessToken = cookies.get(CUBOH_ACCESS);
	const cookieRefreshToken = cookies.get(CUBOH_REFRESH);
	if (cookieAccessToken && cookieRefreshToken) return SOURCE_CHOWNOW;
};
export const getAccessToken = () => {
	return getValueFromLocalStorageOrCookies(ACCESS, CUBOH_ACCESS);
};

export const getRefreshToken = () => {
	return getValueFromLocalStorageOrCookies(REFRESH, CUBOH_REFRESH);
};

export const getAuthorizationHeader = () =>
	getAccessToken() ? 'Bearer ' + getAccessToken() : null;
