import dayjs from 'dayjs';
import { AiOutlineCar } from 'react-icons/ai';
import { GiMeal, GiHotMeal, GiPaperBagFolded } from 'react-icons/gi';
import { MdDeliveryDining, MdOutlineTakeoutDining } from 'react-icons/md';

import {
	ChowNowReceipt,
	ChowNowDelivery,
	ChowNowDineIn,
	ChowNowCar,
} from 'components/ChowNowIcon';

//Start / End Dates
export const START_DAY = dayjs().startOf('day').format('YYYY/MM/DD hh:mm:ss a');
export const END_DAY = dayjs().endOf('day').format('YYYY/MM/DD hh:mm:ss a');

//2.9%
export const STRIPE_PROCESSING_FEE_PERCENT = 0.029;
// .30 cents
export const STRIPE_PROCESSING_FEE_STATIC = 0.3;
// 2.7%
export const STRIPE_TERMINAL_PROCESSING_FEE_PERCENT = 0.027;
// 0.05 cents
export const STRIPE_TERMINAL_PROCESSING_FEE_STATIC = 0.05;
// 0.15 cents
export const STRIPE_TERMINAL_INTERAC_PROCESSING_FEE_STATIC = 0.15;

/** Actions Types */
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_CUSTOM_RANGE = 'SET_CUSTOM_RANGE';
export const SET_SALES_GROUPED_PER_HOUR = 'SALES_GROUPED_PER_HOUR';
export const SET_SALES_PER_MERCHANT = 'SALES_PER_MERCHANT';
export const SET_SEND_ALERT = 'SET_SEND_ALERT';
export const SET_ACTIVE_APPS = 'SET_ACTIVE_APPS';
export const SET_ORDER_REPORT = 'SET_ORDER_REPORT';
export const SET_STOREFRONT_ORDER_REPORT = 'SET_STOREFRONT_ORDER_REPORT';
export const SET_CONTROL_REPORT = 'SET_CONTROL_REPORT';
export const SET_POS_ERROR_REPORT = 'SET_POS_ERROR_REPORT';
export const SET_ADJUSTMENT_REPORT = 'SET_ADJUSTMENT_REPORT';
export const SET_CANCELLATION_REPORT = 'SET_CANCELLATION_REPORT';
export const SET_ITEM_REPORT = 'SET_ITEM_REPORT';
export const SET_MODIFIER_REPORT = 'SET_MODIFIER_REPORT';
export const SET_REPORTING_ERROR = 'SET_REPORTING_ERROR';
export const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL';
export const SET_SELECTED_COMPANIES_AND_MERCHANTS =
	'SET_SELECTED_COMPANIES_AND_MERCHANTS';
export const SET_APP_REPORT_FILTER = 'SET_APP_REPORT_FILTER';
export const SET_SHOW_CANCELLED_FILTER = 'SET_SHOW_CANCELLED_FILTER';
export const SET_ACTION_BAR_SELECT_OPTIONS = 'SET_ACTION_BAR_SELECT_OPTIONS';
export const SET_ACTION_BAR_SELECT_VALUES = 'SET_ACTION_BAR_SELECT_VALUES';

export const CHARGE = 'charge';
export const REFUND = 'refund';

export const RESTAURANT = 'RESTAURANT_IS_CHARGED';

// Fulfillment Types
export const DELIVERY = 'DELIVERY';
export const DELIVERY_BY_RESTAURANT = 'DELIVERY BY RESTAURANT';
export const DINE_IN = 'DINE_IN';
export const CURBSIDE = 'CURBSIDE';
export const PICKUP = 'PICKUP';
export const KIOSK = 'KIOSK';
export const KIOSK_DINE_IN = 'KIOSK - DINE IN';
export const KIOSK_TAKE_OUT = 'KIOSK - TAKE OUT';

export const CHOWNOW_FULFILLMENT_TYPES = {
	[DELIVERY]: {
		key: DELIVERY,
		Icon: ChowNowDelivery,
		label: 'Delivery',
	},
	[DELIVERY_BY_RESTAURANT]: {
		key: DELIVERY_BY_RESTAURANT,
		Icon: ChowNowDelivery,
		label: 'Self Delivery',
	},
	[DINE_IN]: {
		key: DINE_IN,
		Icon: ChowNowDineIn,
		label: 'Dine In',
	},
	[CURBSIDE]: {
		key: CURBSIDE,
		Icon: ChowNowCar,
		label: 'Curbside',
	},
	[PICKUP]: {
		key: PICKUP,
		Icon: ChowNowReceipt,
		label: 'Pickup',
	},
	[KIOSK_DINE_IN]: {
		key: KIOSK_DINE_IN,
		Icon: ChowNowDineIn,
		label: 'Kiosk - Dine In',
	},
	[KIOSK_TAKE_OUT]: {
		key: KIOSK_TAKE_OUT,
		Icon: ChowNowReceipt,
		label: 'Kiosk - Take Out',
	},
};

export const FULFILLMENT_TYPES = {
	[DELIVERY]: {
		key: DELIVERY,
		Icon: MdDeliveryDining,
		label: 'Delivery',
	},
	[DELIVERY_BY_RESTAURANT]: {
		key: DELIVERY_BY_RESTAURANT,
		Icon: MdDeliveryDining,
		label: 'Self Delivery',
	},
	[DINE_IN]: {
		key: DINE_IN,
		Icon: GiHotMeal,
		label: 'Dine In',
	},
	[CURBSIDE]: {
		key: CURBSIDE,
		Icon: AiOutlineCar,
		label: 'Curbside',
	},
	[PICKUP]: {
		key: PICKUP,
		Icon: GiPaperBagFolded,
		label: 'Pickup',
	},
	[KIOSK_DINE_IN]: {
		key: KIOSK_DINE_IN,
		Icon: GiMeal,
		label: 'Kiosk - Dine In',
	},
	[KIOSK_TAKE_OUT]: {
		key: KIOSK_TAKE_OUT,
		Icon: MdOutlineTakeoutDining,
		label: 'Kiosk - Take Out',
	},
};

export const TEST_ORDER = 'TEST ORDER';

export const ORDER = 'order';
export const STOREFRONT_ORDER = 'storefront-order';
export const ITEM = 'item';
export const ADJUSTMENT = 'adjustment';
export const CANCELLATIONS = 'cancellations';
export const CONTROL = 'control';
export const ANALYTICS = 'analytics';
export const MODIFIER = 'modifier';
export const ORDER_DETAIL = 'orderDetail';
export const POS_ERROR = 'pos-error';

export const ERROR = 'error';
export const SUCCESS = 'success';

export const CUBOH = 'CUBOH';

export const APP_ICON_BASE_URL =
	'https://assets.cuboh.net/android-image-resources';

export const ACTIVE = 'Active';
export const IN_PROGRESS = 'In Progress';
export const COMPLETED = 'Completed';
export const INACTIVE = 'Inactive';
export const SCHEDULED = 'Scheduled';
export const CANCELLED = 'Canceled Orders';

export const ORDER_STATUS_CANCELLED_TEXT = 'Cancelled';

export const CANCEL_TYPES = {
	RESTAURANT: 'restaurant',
	DELIVERY_APP: 'delivery_application',
};

export const CANCEL_TYPES_FILTERS = [
	{
		text: 'Restaurant',
		value: 'Restaurant',
	},
	{ text: 'Delivery Application', value: 'Delivery Application' },
];

export const TAX_AND_FEE_LINES_ITEMS = [
	'Cuboh Service Fee',
	'Delivery Service Fee',
	'Tax',
	'Tip',
];

export const INTEGRATION_NAME_MAP = {
	ubereats: 'Uber Eats',
	skip: 'SkipTheDishes',
	doordash: 'DoorDash',
	often: 'Auphan',
	squareapp: 'Square',
	allsetdev: 'Allset (Dev)',
	revel: 'Revel',
	clover: 'Clover',
	lunchbox: 'Lunchbox',
	ritual: 'Ritual',
	sociavore: 'Sociavore',
	chownow: 'ChowNow',
	grubhub: 'GrubHub',
	cuboh: 'Cuboh',
	flipdish: 'FlipDish',
	allset: 'Allset',
	mixbowl: 'Mixbowl',
	ezcater: 'EZCater',
	ezcaterdev: 'EZCater (Dev)',
	lightspeed: 'Lightspeed',
};

export const STOREFRONT_APPS = [
	{
		value: 'CUBOH',
		label: 'Cuboh',
		imageUrl: 'cuboh',
	},
];

export const KIOSK_APPS = [
	{
		value: 'KIOSK',
		label: 'Kiosk',
		imageUrl: 'kiosk',
	},
];
